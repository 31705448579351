import React, { useMemo, useState } from "react";
import { IArticle, IArticleCategory } from "../components/article/types";
import { IContent } from "../contexts/ContentContext";
import { IDropdown } from "../components/dropdown/Dropdown";
import { IDropdownItem } from "../components/dropdown/types";
import ArticleList from "../components/article/ArticleList";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import ContentSection from "../components/contentSection/ContentSection";
import Filter from "../components/filter/Filter";
import Page from "../components/page/Page";
import "./categorizedArticles.css";

export default ({
  pageContext
}: {
  pageContext: {
    category: IArticleCategory;
    categoryArticles: IArticle[];
    content: IContent;
  };
}) => {
  const { category, categoryArticles, content } = pageContext;
  const { metaDescription, metaTitle } = category;
  const [filterSubCategory, setFilterSubCategory] = useState("");

  const subCategories: IDropdownItem[] = useMemo(
    () => [
      {
        id: "",
        value: "All categories"
      },
      ...[
        ...new Set(
          categoryArticles
            .map(article => article.tags?.[0]?.tag)
            .sort((tagA, tagB) => (tagA > tagB ? 1 : -1))
        )
      ].map(tag => ({
        id: tag,
        value: tag
      }))
    ],
    [categoryArticles]
  );

  const dropdowns: IDropdown[] = useMemo(
    () => [
      {
        items: subCategories,
        selectedItem: subCategories[0],
        onItemSelected: (subCategory: IDropdownItem) =>
          setFilterSubCategory(subCategory.id)
      }
    ],
    [subCategories, setFilterSubCategory]
  );

  const filteredCategoryArticles = !!filterSubCategory
    ? categoryArticles.filter(
        article =>
          article.tags.findIndex(tag => tag.tag === filterSubCategory) > -1
      )
    : categoryArticles;

  return (
    <div>
      <Page
        content={content}
        seo={{ description: metaDescription.metaDescription, title: metaTitle }}
      >
        <BreadCrumbs
          dynamic
          crumbsArray={[
            {
              name: "Impact",
              redirectUrl: "/impact"
            },
            {
              name: category.category
            }
          ]}
        />
        <section className="categorized-articles">
          <Filter
            dropdowns={dropdowns}
            isSticky
            mobileLabel="Filter categories"
            numResults={filteredCategoryArticles.length}
          />
          <ContentSection
            description1={{ description1: category.description.description }}
            title={category.category}
          />
          {filteredCategoryArticles.length && (
            <ArticleList
              type="Hero"
              list={filteredCategoryArticles.slice(0, 1)}
            />
          )}
          <ArticleList
            hasScroll={false}
            showFullList
            list={filteredCategoryArticles.slice(1)}
            type="Thumbnail"
          />
        </section>
      </Page>
    </div>
  );
};
